
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class ComponentDemo extends Vue {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch;
  private iFrameSource = ''

  mounted (): void {
    this.updateIFrameSource()
  }

  private iframedebounce: number | undefined = undefined
  // iframe
  updateIFrameSource (): void {
    window.clearTimeout(this.iframedebounce)
    this.iframedebounce = window.setTimeout(() => {
      Services.API.MyWebsites.GetWebsitePreview(this.branch.BusinessBranchId)
        .then((render) => {
          this.iFrameSource = render
          console.log('render')
          console.log(render)
        })
        .catch((error) => {
          console.log('errored')
          console.log(error)
        })
    }, 1000)
  }
}

