import { render, staticRenderFns } from "./ComponentDemo.vue?vue&type=template&id=0cffc721&scoped=true&"
import script from "./ComponentDemo.vue?vue&type=script&lang=ts&"
export * from "./ComponentDemo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cffc721",
  null
  
)

export default component.exports